import { decode as base32Decode, encode as base32Encode } from "hi-base32"

const asciiOnly = true

export const encode = (globalId: string) => base32Encode(globalId, asciiOnly).toLowerCase()

export const decode = (globalId: string) => {
  const base32Format = globalId.toUpperCase()
  try {
    return base32Decode(base32Format, asciiOnly)
  } catch (err) {
    if (isLegacy(globalId)) {
      return decodeLegacy(globalId)
    }
    throw err
  }
}

// old format had both lower + upper (base64)
export const isLegacy = (globalId: string) => globalId.toUpperCase() !== globalId && globalId.toLowerCase() !== globalId

export const decodeLegacy = (globalId: string) => Buffer.from(globalId, "base64").toString("utf8")
export const encodeLegacy = (globalId: string) => Buffer.from(globalId, "utf8").toString("base64")
