import * as React from "react"
import { useHistory, useLocation } from "react-router-dom"
import { ThemeContext } from "styled-components/macro"
import { emptyArray } from "../../../../common/misc-utils"
import PickUtils from "../../../../common/pick-utils"
import A from "../../../components/A"
import Code from "../../../components/Code"
import usePoolData, { buildPickUtils } from "../../../hooks/usePoolData"
import { DebugPanelModalBody } from "../styles/SiteNav.styles"
import { IDebugPanelSharedProps } from "../typings"
import { RESET_USER_MUTATION } from "../../queries"
import { useMutation } from "@apollo/client"
import { ButtonSmallBase } from "../../../components/Button"
import Spinner from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Spinner"
import { findMatchedRoute } from "../../../utils/async-route-utils"
import routes from "../../../../routes"
import constants from "../../../../common/constants"
import { fromGlobalId } from "../../../../common/global-ids"

const CodeSection = ({ header, item }) => {
  return (
    <details>
      <summary>
        <code>{header}</code>
      </summary>
      <Code>{JSON.stringify(item, null, 2)}</Code>
    </details>
  )
}

const DebugPanelModal: React.FC<IDebugPanelSharedProps> = (props) => {
  const history = useHistory()
  const location = useLocation()
  const route = findMatchedRoute(routes, location.pathname)
  const match = route.match
  const [showLoader, setShowLoader] = React.useState(false)
  const poolData = usePoolData({ history, location, match })
  const [resetUserMutation] = useMutation(RESET_USER_MUTATION)
  // const resetUserClick = (resetUserMutation) => async (event) => {
  const resetUserClick = async (resetUserMutation) => {
    // e.preventDefault();
    setShowLoader(true)
    if (
      window.confirm(
        `Are you sure you want to clear your user data?  WARNING: this should ONLY be done on "burn" accounts, as it will delete all entries, pools, and the user in the Picks platform!`,
      )
    ) {
      const resp = await resetUserMutation()
      console.log(resp)
      window.alert(`Result: ${resp.data.resetUser}`)
      window.location.replace("/")
    } else {
      setShowLoader(false)
    }
  }
  const { detailedPeriod, detailedEntry, centralCurrentUsersEntriesQuery, poolDetail, segmentsForArea } = poolData
  const themeContext = React.useContext(ThemeContext)
  const currentPickUtils = React.useRef<PickUtils | null>(null)
  const pickUtils = buildPickUtils(poolData, currentPickUtils.current, detailedEntry?.picks, detailedEntry?.id)
  if (currentPickUtils.current !== pickUtils) {
    currentPickUtils.current = pickUtils
  }
  const events = pickUtils?.events || emptyArray
  const themeKeys = Object.keys(themeContext)
  return (
    <DebugPanelModalBody>
      <hr />
      <h3>
        <strong>Current User Info:</strong>
      </h3>
      <A to={`/admin/database?id=${centralCurrentUsersEntriesQuery.data?.currentUser.picksDbId}`}>
        Picks User ID: <code>{centralCurrentUsersEntriesQuery.data?.currentUser.picksDbId || "none"}</code>
      </A>
      <CodeSection header="currentUser" item={centralCurrentUsersEntriesQuery.data} />

      <br />
      {!constants.IS_PROD && (
        <ButtonSmallBase type="button" onClick={() => resetUserClick(resetUserMutation)}>
          {(showLoader && <Spinner />) || (
            <>
              Reset Account
              <span role="img" aria-label="Debug Menu">
                ⚠️
              </span>
            </>
          )}
        </ButtonSmallBase>
      )}

      <hr />
      {(poolDetail && (
        <>
          <h3>
            <strong>Area Context Data:</strong>
          </h3>
          <details>
            <summary>
              <code>Events</code>
            </summary>
            {events.map((ev, idx) => {
              return (
                <A to={`${constants.ADMIN_BASE_URL}/gamedata/event/${fromGlobalId(ev.id).id}`} key={ev.id}>
                  {pickUtils?.humanPick(ev.id, pickUtils.getPick(ev.id)?.itemId).join(" ") || `Event ${idx}`}
                  <code>{ev.id}</code>
                </A>
              )
            })}
          </details>
          <CodeSection header="detailedPeriod" item={detailedPeriod} />
          <div style={{ paddingBottom: 10 }}>
            {detailedPeriod && (
              <A to={`${constants.ADMIN_BASE_URL}/gamedata/period/${fromGlobalId(detailedPeriod.id).id}`}>
                <code>Admin Period Link</code>
              </A>
            )}
          </div>
          <CodeSection header="poolDetail" item={poolDetail} />
          <CodeSection header="detailedEntry" item={detailedEntry} />
          {poolDetail?.id && (
            <A to={`/admin/database?id=${poolDetail.id}`} target="_blank">
              Pool in DB
            </A>
          )}
          {detailedEntry?.id && (
            <A to={`/admin/database?id=${detailedEntry.id}`} target="_blank">
              Entry in DB
            </A>
          )}
        </>
      )) || (
        <>
          <h3>
            <strong>Area details:</strong>
          </h3>
          <CodeSection header="segmentsForArea" item={segmentsForArea} />
        </>
      )}

      <hr />
      <h3>
        <strong>Game Context Data:</strong>
      </h3>
      {themeKeys.map((key) => {
        const value = themeContext[key]
        return <CodeSection key={key} header={key} item={value} />
      })}
    </DebugPanelModalBody>
  )
}
export default DebugPanelModal
